body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #201e1f;
  color: white;
  font-family: "Raleway", sans-serif;
}

nav {
  margin: 2rem;
}
.nav-links {
  font-weight: 300;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: right;
  gap: 2rem;
  line-height: 2rem;
  align-items: flex-end;
  margin: 0;
}

.nav-links li a {
  cursor: pointer;
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease;
  border: solid 1px white;
  padding: 0.5rem 2rem;
  border-radius: 20px;
}

.nav-links li a:hover {
  color: #201e1f;
  background-color: white;
  transition: ease 1s;
}

button {
  border: solid 1px white;
  border-radius: 20px;
  background-color: #201e1f;
  color: white;
  padding: 0.5rem 2rem;
  position: relative;
  animation: float 2s infinite ease-in-out;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

button:hover {
  background-color: white;
  color: #201e1f;
  transform: translateY(-5px);
}

button a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

button a:hover {
  color: #201e1f;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

h2 {
  text-decoration: underline;
}
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 4rem);
}

.hero-content {
  text-align: center;
  padding: 2rem;
  width: 100%;
  line-height: 3.5rem;
}
.hero-content h1 {
  font-size: 4rem;
  margin: 0;
  position: relative;
  font-family: span, serif;
  font-weight: 300;
  font-style: italic;
}

.about-section {
  padding: 3em;
  justify-content: center;
  align-items: center;
}

.about-section p {
  text-align: center;
  font-style: italic;
  padding: 2rem;
  font-size: 3vw;
  max-width: 900px;
  margin: 0 auto;
}

.project-section {
  padding: 3rem;
}

.project-card {
  background-color: #f5f5f5;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.image-container {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  padding: 2rem;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  text-align: center;
  color: white;
  white-space: normal;
  word-wrap: break-word;
}

.project-card:hover .card-content {
  opacity: 1;
}

.project-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  justify-items: center;
  padding: 4rem 0;
}

.links {
  display: flex;
  justify-content: center;
}

.links a {
  text-decoration: none;
  color: white;
  margin: 0 0.5rem;
  border: solid 1px #201e1f;
  border-radius: 15px;
  background-color: #201e1f;
  padding: 0.3rem;
  font-size: small;
}

.skills-container {
  align-items: center;
}
.skills {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  font-size: 3rem;
}

.skills-column {
  padding: 2rem;
  gap: 1rem;
}

.skills ul {
  list-style: none;
  padding: 0;
}

.skills ul li {
  margin-bottom: 10px;
}

footer {
  display: flex;
  flex-direction: column;
  font-size: 2.5rem;
  align-items: center;
  padding: 3rem;
}

footer a {
  text-decoration: none;
  color: white;
}

.email {
  font-weight: 500;
  font-style: italic;
  cursor: pointer;
}

.email-link {
  line-height: 0.2rem;
  text-align: center;
}
.email:hover {
  opacity: 70%;
}

.icons {
  padding: 3rem;
}

.fa-brands {
  cursor: pointer;
  padding: 0.5rem;
}

.fa-brands:hover {
  opacity: 70%;
}

@media (max-width: 768px) {
  .about-section p {
    font-size: 5vw;
    padding: 0;
  }

  .skills {
    font-size: 5vw;
  }

  .nav-links {
    justify-content: center;
  }

  .hero-content {
    padding: 3rem 0;
  }

  .hero-section {
    padding: 1rem;
  }

  .project-cards-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 2rem 0;
  }

  .project-card {
    background-color: #f5f5f5;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    width: 100%;
  }

  .image-container {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .project-card .card-content {
    opacity: 1;
  }
}
